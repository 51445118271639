.custom
    padding-bottom: 100px

    \:global
        .center
            max-width: 1024px
            margin: 0 auto


        .title
            display: flex
            margin: 10px
            margin-top: 30px
            align-items: center

            h1
                font-size: 30px
                line-height: 37.5px
                color: #002C8A
                font-weight: 600

            .empty
                margin-left: 20px
                background: #A8ABAE
                text-decoration: none
                font-size: 12px
                line-height: 20px
                border-radius: 10px
                padding: 0 10px
                color: #fff
                cursor: pointer

            input[type="text"]
                font-size: 30px
                line-height: 37.5px
                color: #002C8A
                font-weight: 600
                width: 300px
                border: none
                padding: 0

             
        .dropzone
            flex: 1     

        .dropzone.left
            margin-right: 10px

        .grid-item
            width: 100%
            height: 100%
            box-sizing: border-box

            > div
                width: 216px
                height: 158px
                position: relative
                margin: 10px
                background: #fff

                > div:nth-child(1)
                    width: 1024px
                    height: 724px
                    position: absolute
                    transform: scale(0.22)
                    transform-origin: left top
                    pointer-events: none

                    &.centered
                        transform: scale(0.22) translate(-50%, -50%)
                        margin-top: 0

                > div:nth-child(2)
                    position: absolute
                    bottom: 0px
                    width: 1024px
                    transform: scale(0.22)
                    transform-origin: left bottom
                    pointer-events: none

            .remove 
                position: absolute
                right: 0
                top: 0
                width: 30px
                height: 30px
                background: #002C8A
                border-radius: 15px

                svg 
                    position: absolute
                    right: -3px
                    top: -3px
                    width: 36px
                    height: 36px

    
     