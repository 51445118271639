@font-face
    font-family: "Graphik"
    font-weight: 300
    src: url("~assets/fonts/Graphik-Light.otf")

@font-face
    font-family: "Graphik"
    font-weight: 400
    src: url("~assets/fonts/Graphik-Regular.otf")

@font-face
    font-family: "Graphik"
    font-weight: 500
    src: url("~assets/fonts/Graphik-Medium.otf")

@font-face
    font-family: "Graphik"
    font-weight: 600
    src: url("~assets/fonts/Graphik-Semibold.otf")

@font-face
    font-family: "Graphik"
    font-weight: 600
    font-style: italic
    src: url("~assets/fonts/Graphik-SemiboldItalic.otf")

@font-face
    font-family: "Graphik"
    font-weight: 700
    src: url("~assets/fonts/Graphik-Bold.otf")

@font-face
    font-family: "NoeDisplay"
    font-weight: 800
    src: url("~assets/fonts/NoeDisplay-BlackItalic.otf")

@font-face
    font-family: "AvenirNext"
    font-weight: 400
    src: url("~assets/fonts/AvenirNextLTPro-Regular.otf")

@font-face
    font-family: "AvenirNextMedium"
    font-weight: 600
    src: url("~assets/fonts/avenir-next-medium.ttf")

@font-face
    font-family: "AvenirNext"
    font-weight: 700
    src: url("~assets/fonts/AvenirNextLTPro-Bold.otf")

@font-face
    font-family: "AvenirNext"
    font-weight: 700
    font-style: italic
    src: url("~assets/fonts/AvenirNextLTPro-BoldItalic.ttf")

*
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box

body
    font-family: "Graphik", sans-serif
    font-weight: 400
    font-size: 21px
    line-height: 23.7px

h1,h2,h3,h4,h5,h6,
p
    margin: 0

h1, h2, h3, h4, h5, h6
    font-weight: 400

h1
    font-size: 30px
    line-height: 37.5px

h2
    margin-top: 20px
    font-size: 100%
    line-height: 100%

h1,
h2,
p,
ul
    strong
        font-weight: 600

    &.center
        text-align: center
ul
    margin: 0
    padding: 0
    list-style: none

.text-center
    text-align: center

.text-uppercase
    text-transform: uppercase

.text >
    p + *,
    ul + * 
    margin-top: 17px

.small
    font-size: 17.5px
    line-height: 21px

sup
    vertical-align: super
    line-height: 0
    font-size: 55%

sub
    vertical-align: baseline
    line-height: 0
    font-size: 55%

.clickArea
    position: absolute
    z-index: 1
    left: 0
    top: 0
    width: 100%
    height: 100%

form
    input[type="text"],
    input[type="password"],
    input[type="email"]
        width: 100%
        max-width: 300px
        height: 30px
        border-radius: 15px
        margin-right: 15px
        padding-left: 10px
        border: 1px solid #666

        &:focus
            outline-style: none
            box-shadow: none
