.users
    \:global
        .center
            max-width: 1024px
            margin: 0 auto

        .title
            display: flex
            justify-content: space-between
            align-items: center
            margin-top: 30px
            margin-bottom: 15px

        .user
            margin-top: 10px        

            .head
                display: flex
                color: #003087
                padding: 10px
                background: #E4DAEB
                border-radius: 15px
                cursor: pointer

                strong
                    font-weight: 600

                > span
                    display: block
                    flex: 0 0 50%

                .small
                    font-size: 12px
                    padding-left: 10px

        .form
            padding: 10px
            background: #E4DAEB
            border-radius: 15px

            .double
                display: flex

                > div
                    flex: 0 0 50%

            label 
                display: block
                font-size: 14px
                line-height: 21px
                margin-bottom: 5px

            .buttons
                display: flex
                margin-top: 15px

                span
                    line-height: 30px
                    font-weight: 400
                    font-size: 14px
                    cursor: pointer

                    &.cancel
                        margin-left: 10px

                    &.delete
                        margin-left: auto
                        color: red

        button 
            background: #003087
            height: 30px
            padding: 0 15px
            border-radius: 18px
            border: none
            font-family: "Graphik", sans-serif
            font-weight: 400
            font-size: 14px
            cursor: pointer
            color: #fff