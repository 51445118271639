.folder
	position: fixed
	overflow: hidden
	width: 100vw
	height: 100%
	
	\:global
		.slides
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 100%
			transition: transform 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940)

			@at-root :global(.noanimate)#{&}
				transition-duration: 0s

			> div
				position: absolute
				left: 0
				top: 0
				width: 100%
				height: 100%
				overflow: hidden