.footer
    position: fixed
    left: 0
    bottom: 0
    width: 100%
    height: 85px
    background: #D4D5D7

    &:global(.transparent)
        background: none

    &:global(.white)
        > :global(.logo) > svg
            fill: #fff

        > :global(.buttons) svg
            stroke: #fff

        > :global(.buttons) span
            border-color: #fff

    \:global
        .logo
            position: absolute
            display: block
            width: 146px
            height: 26px
            left: 37px
            top: 30px

            svg
                position: absolute
                left: 0
                width: 100%
                fill: #003087

        .buttons
            position: absolute
            right: 37px
            top: 26px
            list-style: none
            margin: 0
            padding: 0

            li
                float: left
                display: inline-block

                + li 
                    margin-left: 10px

                span 
                    display: flex
                    width: 33px
                    height: 33px
                    border: 1.5px #003087 solid
                    border-radius: 50%
                    cursor: pointer
                    align-items: center
                    justify-content: center

                    svg
                        stroke: #003087                  

        .navigation
            position: absolute
            display: none
            width: 742px
            height: 133px
            bottom: 101px
            right: 86px
            background: #D4D5D6

            &.active
                display: block

            > div
                position: absolute
                overflow: hidden
                top: 5px
                left: 7px
                right: 7px
                bottom: 5px

                > div
                    position: absolute
                    overflow-x: scroll
                    left: 0
                    top: 0
                    width: 100%
                    height: 200%

            .slides
                display: flex

                > div
                    position: relative
                    overflow: hidden
                    flex: 0 0 174px
                    height: 123px
                    background: #fff

                    + div
                        margin-left: 10px

                    > div:nth-child(1)
                        width: 1024px
                        height: 724px
                        position: absolute
                        transform: scale(0.17)
                        transform-origin: left top
                        pointer-events: none

                        &.centered
                            transform: scale(0.17) translate(-50%, -50%)
                            margin-top: 0

                    > div:nth-child(2)
                        position: absolute
                        bottom: 0px
                        width: 1024px
                        transform: scale(0.17)
                        transform-origin: left bottom
                        pointer-events: none