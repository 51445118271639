.dropdown
    position: absolute
    top: 26px
    right: 250px

    \:global
        .button.open
            display: flex
            width: 33px
            height: 33px
            border: 1.5px #003087 solid
            border-radius: 50%
            cursor: pointer
            align-items: center
            justify-content: center

            @at-root :global(.white) &
                border-color: #fff
                
                svg path
                    fill: #fff

        .button.add
            display: inline-block
            border: 2px solid #fff
            border-radius: 18px
            font-weight: 400
            padding: 0 10px
            height: 36px
            line-height: 36px
            width: auto
            min-width: 120px
            max-width: 260px
            cursor: pointer
            text-overflow: ellipsis
            overflow: hidden
            white-space: nowrap

            + .button.add
                margin-top: 10px
    
    > div
        position: absolute
        padding: 15px
        bottom: 60px
        left: -137px
        width: 300px
        background: #002C8A
        border-radius: 15px
        text-align: center
        color: #fff
        display: flex
        flex-direction: column
        align-items: center

        svg
            position: absolute
            top: 100%
            transform: rotate(90deg) translateY(-82%)
            width: 20px
            transform-origin: left top

    h6 
        font-weight: 400
        font-size: 21px
        line-height: 23.7px
        margin-bottom: 10px

